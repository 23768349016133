.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Resetting some browser defaults to ensure consistency across different browsers */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* You can choose a different font, but Roboto is clean and professional */
body {
  font-family: 'Roboto', sans-serif;
  background-color: #f4f4f4;
}

/* Use 100% of the width for smaller screens with a maximum width matching the width of your content */
.container {
  width: 100%;
  max-width: 400px; 
  margin: auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Form element styling */
.form-label {
  font-weight: bold;
  margin-bottom: 8px;
}

.form-control {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

/* Button styling */
.btn {
  display: block;
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 4px;
  background-color: #008CBA; /* A professional blue */
  color: #ffffff;
  font-weight: bold;
  cursor: pointer;
}

.btn-outline-secondary {
  background-color: transparent;
  border: 1px solid #008CBA; /* Same blue as above */
  color: #008CBA;
}

/* Error message styling */
.alert-danger {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
  padding: 10px;
  border-radius: 4px;
}


/* Barcode container should take up the full width of its parent to ensure it resizes properly */
.barcode-container {
  width: 100%; /* Changed from 52% to 100% to fill the container */
  display: flex;
  justify-content: center;
  overflow-x: hidden; /* Prevent horizontal scrolling if the barcode overflows */
}

/* Add CSS for the clear button */
.btn-clear {
  margin-left: auto; /* Push the button to the right by using margin-left: auto */
}

.mb-3 {
  max-width: 100%;
}

/* Additional global style to prevent horizontal scrolling */
body {
  overflow-x: hidden; /* This prevents horizontal scrolling at the body level */
}

